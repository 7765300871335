<template>
	<div class="order_wrap">
		<v-container class="">
			<div class="message_box py-8 px-6" v-if="invalid">
				<h3 class="d1 mb-2">
					<strong
						>안내문자 발송된 고객정보와 PASS 인증된 고객정보가 다릅니다.</strong
					>
				</h3>
				<span>계약서를 다시 확인하시려면 담당 판매인에게 연락해 주세요. </span>
			</div>
			<div class="message_box py-8 px-6" v-else>
				<h3 class="d1 mb-2"><strong>열람 기간이 만료되었습니다.</strong></h3>
				<span>계약서를 다시 확인하시려면 담당 판매인에게 연락해 주세요. </span>
			</div>
		</v-container>
		<!-- <div class="bottom_box px-6">
			<v-btn color="primary" class="col_1_small" @click="complete()">
				{{ btnMsg }}
			</v-btn>
		</div> -->
	</div>
</template>

<script>
import computeds from '@/mixins/computeds'
export default {
	mixins: [computeds],
	props: {},
	data() {
		return {
			invalid: false,
			custSelf: false, // 직접입력: true, 코디대행: false
			btnMsg: ''
		}
	},
	mounted() {
		// 고객직접 계약확인에서 PASS인증 후 고객정보 불일치시 invalid: true
		const { invalid } = this.$route.params
		this.invalid = invalid
		this.custSelf = this.$store.getters['verify/custSelf']
		if (window.BMManager) {
			this.btnMsg = '확인'
		} else {
			this.btnMsg = '코웨이 홈페이지로 이동'
		}
	},
	methods: {
		complete() {
			if (window.BMManager) {
				this.$router.push({ name: 'order-status' })
			} else {
				location.href = `${this.cowayHome}`
			}
		}
	}
}
</script>

<style scoped lang="scss"></style>
